@font-face {
font-family: 'veloSerifDisplay';
src: url(/_next/static/media/7f60feea74c11833-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'veloSerifDisplay Fallback';src: local("Arial");ascent-override: 73.78%;descent-override: 17.15%;line-gap-override: 0.00%;size-adjust: 125.38%
}.__className_270c81 {font-family: 'veloSerifDisplay', 'veloSerifDisplay Fallback';font-weight: 700;font-style: normal
}

